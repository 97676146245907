.table-container {
  position: relative;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.column-header {
  position: relative;
  overflow: visible !important;
}

.header-input {
  font-size: 1.2rem !important;
  font-weight: bold !important;
}

.column-actions,
.row-actions {
  position: absolute;
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.column-actions {
  position: absolute;
  top: 100%;
  right: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.column-actions .MuiIconButton-root {
  padding: 4px;
}

.row-actions {
  position: sticky;
  right: 0;
  background-color: white;
  z-index: 1;
}

.MuiTableRow-root:hover .row-actions,
.MuiTableRow-root:focus-within .row-actions {
  opacity: 1;
}

.MuiTableRow-root {
  position: relative;
}

.MuiTableCell-root {
  position: relative;
}

.MuiIconButton-root {
  padding: 4px !important;
}

.add-column-cell,
.add-row-row {
  background-color: #f5f5f5;
}

.add-column-cell {
  width: 48px;
}

.add-row-row td {
  text-align: center;
}

.react-resizable {
  position: relative;
}

.react-resizable-handle {
  position: absolute;
  width: 10px;
  height: 100%;
  bottom: 0;
  right: -5px;
  cursor: col-resize;
  z-index: 2;
}

.column-header .react-resizable-handle {
  position: absolute;
  right: -5px;
  bottom: 0;
  top: 0;
  width: 10px;
  height: 100%;
  cursor: col-resize;
  z-index: 1;
}

.column-header .react-resizable-handle::after {
  content: "";
  position: absolute;
  right: 3px;
  bottom: 0;
  top: 0;
  width: 4px;
  background-color: #ddd;
  transition: background-color 0.2s ease;
}

.column-header:hover .react-resizable-handle::after,
.column-header.resizing .react-resizable-handle::after {
  background-color: #2196f3;
}

.column-header.resizing {
  background-color: rgba(33, 150, 243, 0.1);
}

.add-column-cell.actions-column {
  width: 25px !important;
  min-width: 25px !important;
  max-width: 25px !important;
  padding: 0 !important;
}

.actions-column .MuiIconButton-root {
  padding: 2px !important;
}

.tabulator {
  min-width: 100%;
  width: auto !important;
}

.tabulator .tabulator-header .tabulator-col {
  background-color: #f5f5f5;
}

.tabulator-row .tabulator-cell {
  padding: 8px;
}

.tabulator-row .tabulator-cell.tabulator-editing {
  padding: 0;
}

.tabulator-row .tabulator-cell.tabulator-editing input {
  width: 100%;
  height: 100%;
  border: none;
  outline: none;
  padding: 8px;
}

.tabulator-tableholder {
  overflow-x: auto !important;
  overflow-y: auto !important;
}