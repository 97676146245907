.kanban-board {
    display: flex;
    justify-content: space-between;
    height: calc(100vh - 200px);
    background-color: #f0f8ff;
    padding: 16px;
  }
  
  .kanban-column {
    width: calc(100% / var(--column-count));
    height: 100%;
    padding: 0 8px;
  }
  
  .kanban-column-content {
    height: 100%;
    overflow-y: auto;
    background-color: white;
    border-radius: 8px;
    box-shadow: 0 3px 6px rgba(0,0,0,0.16);
  }
  
  .kanban-column-header {
    background-color: #0c2137;
    color: white;
    padding: 12px;
    border-top-left-radius: 8px;
    border-top-right-radius: 8px;
    font-weight: bold;
    text-align: center;
  }
  
  .kanban-column-cards {
    padding: 16px;
  }

.kanban-card {
  margin-bottom: 8px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0,0,0,0.1);
  background-color: white;
}

.kanban-card-content {
  padding: 12px;
}

.kanban-card-title {
  font-size: 16px;
  font-weight: bold;
  margin-bottom: 8px;
  line-height: 1.2;
}

.kanban-card-meta {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
}

.kanban-card-creator {
  display: flex;
  align-items: center;
}

.kanban-card-creator-avatar {
  width: 24px;
  height: 24px;
  margin-right: 8px;
}

.kanban-card-date {
  font-size: 12px;
  color: #666;
}

.kanban-card-assignees {
  margin-bottom: 8px;
}

.kanban-card-footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.kanban-card-chips {
  display: flex;
  gap: 4px;
}

.kanban-card-icons {
  display: flex;
  gap: 4px;
}