@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';
@import 'sweetalert2/dist/sweetalert2.min.css';
/* You can add any additional custom styles here */
/* Custom styles */
.bg-custom-gray {
    background-color: #f0f0f068;
}

body {
    background-color: #fff7fbc9;
}
body, #root {
    width: 100%;
    max-width: 100%;
    overflow-x: hidden;
  }