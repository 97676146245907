.lightbox-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  .lightbox-content {
    background: white;
    padding: 30px;
    border-radius: 10px;
    max-width: 500px;
    width: 90%;
  }
  
  .lightbox-content h2 {
    font-size: 24px;
    margin-bottom: 10px;
  }
  
  .lightbox-content h3 {
    font-size: 18px;
    color: #666;
    margin-bottom: 20px;
  }
  
  .lightbox-content ul {
    margin-left: 20px;
    margin-bottom: 20px;
  }
  
  .close-btn {
    background-color: #007bff;
    color: white;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
  }