.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: linear-gradient(135deg, #667eea 0%, #764ba2 100%);
}

.login-card {
  background-color: #1e2139;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  width: 500px;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo {
  width: 100px;
  margin-bottom: 1rem;
}

.welcome-text {
  color: #ffffff;
  margin-bottom: 0.5rem;
  text-align: center;
}

.subtitle-text {
  color: #a0a0a0;
  text-align: center;
  margin-bottom: 2rem;
  font-size: 0.9rem;
}

.login-form {
  width: 100%;
}

.input-field {
  margin-bottom: 1rem !important;
}

.input-field .MuiInputBase-root {
  background-color: #2a2f4a;
}

.input-field input {
  color: #ffffff;
}

.input-field label {
  color: #a0a0a0;
}

.login-button {
  margin-top: 1rem !important;
  background-color: #4a69dd !important;
  height: 48px;
  font-size: 1rem;
}

.links-container {
  width: 100%;
  padding: 1rem 0;
  border-top: 1px solid #2a2f4a;
  margin-top: 1rem;
}

.link-text {
  color: #ffffff;
  text-decoration: none;
  font-size: 0.9rem;
}

.link-text:hover {
  text-decoration: underline;
}